//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-416:_3548,_8768,_8512,_4928,_3280,_9508,_6788,_7664;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-416')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-416', "_3548,_8768,_8512,_4928,_3280,_9508,_6788,_7664");
        }
      }catch (ex) {
        console.error(ex);
      }