import { createSelector } from '@reduxjs/toolkit';

import { FacetValue } from 'Models/index.ts';
import facetsConfig from 'Models/uiConfig/facetsConfig.js';
import { createFacetCollectionSelector, createFacetValueArraySelector } from 'Modules/selectors.js';
import { infiniteScrollEnabled } from '!!extract-features!Stores/amtuning-canada/config.js';

const searchSelector = (state) => state.search;

export const searchLastSearchOptionsSelector = (state) => searchSelector(state).lastSearchOptions;

// request

export const searchRequestSelector = (state) => searchSelector(state).request;

export const searchRequestSelectionSelector = (state) => searchRequestSelector(state).selection;

export const searchRequestFilterQuerySelector = (state) => searchRequestSelector(state).filterQuery;

export const searchRequestQuerySelector = (state) => searchRequestSelector(state).query;

export function createMaxCountSelector(field) {
  const cache = {};
  if (!cache[field]) {
    cache[field] = (state) => state.search.request.maxCountByField[field] || null;
  }
  return cache[field];
}

// response

const searchResponseSelector = (state) => searchSelector(state).response;

const searchFacetsSelector = createFacetCollectionSelector(
  searchResponseSelector,
  (response) => response.facets,
);

function filterByFields(fields, ignoreFields) {
  return (collection) =>
    fields.length || ignoreFields
      ? collection.filter((f) =>
          fields.length ? fields.includes(f.field) : ignoreFields ? !ignoreFields.includes(f.field) : true,
        )
      : collection;
}

export function createFilteredFacetsSelector(fields = [], ignoreFields) {
  return createSelector(searchFacetsSelector, filterByFields(fields, ignoreFields));
}

const facetSelectorCache = {};
export function createFacetSelector(field) {
  if (!facetSelectorCache[field]) {
    facetSelectorCache[field] = createSelector(searchFacetsSelector, (facets) => facets.get(field));
  }
  return facetSelectorCache[field];
}

const noSubFacets = [];
export function createSubFacetsSelector(field) {
  const { childFields, singleFacet } = facetsConfig.getChildrenForField(field);

  return singleFacet
    ? createSelector(searchFacetsSelector, (facets) =>
        childFields.map((field) => facets.get(field) || { field }),
      )
    : () => noSubFacets;
}

export function createSearchFacetHasSelectionSelector({ field, selection }) {
  const subFacetsSelector = createSubFacetsSelector(field);
  return createSelector(
    subFacetsSelector,
    (subFacets) => !!(selection.length || subFacets.some(({ selection = [] }) => selection.length)),
  );
}

export const searchQuerySelector = (state) => searchResponseSelector(state).query;

export const searchOriginalQuerySelector = (state) => searchResponseSelector(state).originalQuery;

export const searchResponseSelectionSelector = createSelector(
  searchFacetsSelector,
  searchQuerySelector,
  (state) => state.search.request.filterQuery,
  (facets, query, filterQuery) =>
    [
      query && FacetValue.create({ field: 'query', term: query }),
      filterQuery && FacetValue.create({ field: 'filterQuery', term: filterQuery }),
      ...facets.selection,
    ].filter(Boolean),
);

export function createFilteredResponseSelectionSelector(fields = [], ignoreFields) {
  return createFacetValueArraySelector(searchResponseSelectionSelector, filterByFields(fields, ignoreFields));
}

export function createShouldShowFacetPanelSelector(fields, ignoreFields) {
  const facetsSelector = createFilteredFacetsSelector(fields, ignoreFields);
  const selectionSelector = createFilteredResponseSelectionSelector(fields, ignoreFields);
  return (state) => facetsSelector(state).length || selectionSelector(state).length;
}

export const searchItemsSelector = (state) => searchResponseSelector(state).items;

export const searchItemsLengthSelector = (state) => searchItemsSelector(state).length;

export const noItemsReceivedSelector = (state) => !searchItemsLengthSelector(state);

export const totalHitsSelector = (state) => searchResponseSelector(state).totalHits;

export const allItemsReceivedSelector = (state) =>
  searchItemsLengthSelector(state) === totalHitsSelector(state);

export const loadMoreFailedSelector = (state) =>
  infiniteScrollEnabled && !totalHitsSelector(state) && !!searchItemsLengthSelector(state);

export const searchResponseErrorSelector = (state) => searchResponseSelector(state).error;

export const searchResponseStateSelector = (state) => searchResponseSelector(state).state;

export const searchResponseMessagesSelector = (state) => searchResponseSelector(state).messages;

export const searchResponseExtraSelector = (state) => searchResponseSelector(state).extra;

// combined

export const lastPageNumberSelector = (state) =>
  Math.floor((totalHitsSelector(state) - 1) / state.search.request.pageSize);

export const nextPageSizeSelector = (state) =>
  Math.min(state.search.request.pageSize, totalHitsSelector(state) - searchItemsLengthSelector(state));

export const pageFromSelector = (state) =>
  infiniteScrollEnabled ? 1 : state.search.request.pageNumber * state.search.request.pageSize + 1;

export const pageToSelector = (state) =>
  Math.min((state.search.request.pageNumber + 1) * state.search.request.pageSize, totalHitsSelector(state));
