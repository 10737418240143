import shopifyChromiumGenericV4Defaults from '../shopify-chromium-generic-v4/config.js';

const wheelsFields = shopifyChromiumGenericV4Defaults.Widgets.find(
  (w) => w.name === 'FacetBarWheels',
)?.fields;

export default {
  includes: ['shopify-chromium-generic-v4'],
  ...shopifyChromiumGenericV4Defaults,
  SearchRequestDefaults: {
    ...shopifyChromiumGenericV4Defaults.SearchRequestDefaults,
    extra: {
      ...shopifyChromiumGenericV4Defaults.SearchRequestDefaults.extra,
      doNotHideWheelsTiresFacets: window.location.pathname === '/',
    },
  },
  Widgets: [
    ...shopifyChromiumGenericV4Defaults.Widgets.filter((w) => w.name !== 'HeaderVehicleWidget'),
    {
      name: 'HomeVehicleWidget_tab',
      type: 'VehicleWidget',
      location: '.cm_tabs .cm_tab-content.cm_vehicle-search',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 800,
    },
    {
      name: 'WheelPanel_tab',
      type: 'RequestPanel',
      location: '.cm_tabs .cm_tab-content.cm_wheel-search',
      template: 'HomeWheelTireBar',
      redirectUrl: '/collections/wheels',
      needInitRequest: true,
      selectFields: wheelsFields,
    },
  ],
};
